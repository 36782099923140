.loader_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    min-height: 50vh;
}

.container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    min-height: 50vh;
    flex-direction: column;
}

.tea {
    --secondary: #33406F;
    transform: scale(1.1);
}

.teabag {
    transform-origin: top center;
    transform: rotate(3deg);
    animation: swing 2s linear infinite;
}

.steamL {
    stroke-dasharray: 13;
    stroke-dashoffset: 13;
    animation: steamLarge 2s linear infinite;
}

.steamR {
    stroke-dasharray: 9;
    stroke-dashoffset: 9;
    animation: steamSmall 2s linear infinite;
}

.loading-text {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: .8rem;

    & > span {
        font-size: 1.6rem;
    }
}

@keyframes swing {
    50% {
        transform: rotate(-3deg);
    }
}

@keyframes steamLarge {
    0% {
        stroke-dashoffset: 13;
        opacity: .6;
    }
    100% {
        stroke-dashoffset: 39;
        opacity: 0;
    }
}

@keyframes steamSmall {
    10% {
        stroke-dashoffset: 9;
        opacity: .6;
    }
    80% {
        stroke-dashoffset: 27;
        opacity: 0;
    }
    100% {
        stroke-dashoffset: 27;
        opacity: 0;
    }
}