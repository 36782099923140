.account-card-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: fit-content;
}

.cardHeader {
    display: flex;
    justify-content: space-between;
    width: 100%;
    font-family: "Dosis";
    font-weight: 500;
}

.title-container {
    display: flex;
    flex-direction: column;
    width: fit-content;
    height: fit-content;
}

.title-user {
    border: none;
    padding: 0;
    margin: 0 8px 0 0;
    font-size: 2.6rem;
}

.title-level {
    color: var(--dark-grey);
    width: fit-content;
    font-size: 1.4rem;
    line-height: 1.4rem;
    padding-left: .5rem;
    padding-right: .5rem;
    margin-top: .8rem;
    border-left: .1rem solid var(--primary-color-dark);
    border-right: .1rem solid var(--primary-color-dark);
}

.link-separator {
    display: flex;
    width: fit-content;
    height: fit-content;
    font-size: 2rem;
    font-weight: 500;
}

.credential-container {
    display: flex;
    width: fit-content;
    height: fit-content;
    font-size: 1.6rem;
}

.title-credential {
    margin: 0 1rem .5rem 0;
}

.card-footer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: fit-content;
    margin-top: 1rem;
}

.button-separator {
    display: flex;
    width: fit-content;
    height: fit-content;
    font-size: 1.6rem;
    margin-bottom: .5rem;
    font-weight: 500;
}