.main {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    width: 100%;
    height: 6rem;
    background-color: var(--background-white);
}

.placeHolderAsTitle {
    font-family: "Quicksand";
    font-size: 1rem;
    font-weight: 400;
    margin: 0 0 0 1rem;
    width: fit-content;
    color: #3d3d3d;
    transition: all ease-in-out 0.5s;
    cursor: pointer;
    z-index: 1;
    background-color: var(--background-white);
    padding: 0 .5rem;
    transform: translateY(1.2rem);
    z-index: 7;
}

.placeHolder {
    color: #818181;
    transform: translateY(3.2rem);
    font-size: 1.4rem;
    letter-spacing: 0.2rem;
    z-index: 5;
}

.select-container {
    position: relative;
    min-width: 20rem;
    z-index: 6;
}

.select {
    width: 100%;
    font-family: "Quicksand";
    font-size: 1.6rem;
    font-weight: 400;
    margin: 0.4rem 0 0 0;
    padding: .8rem 2.8rem .8rem 1rem;
    border: 0.1rem solid var(--primary-color);
    border-radius: .8rem;
    box-sizing: border-box;
    appearance: none;
    background: none;
    z-index: 4;
    position: relative;
    cursor: pointer;
}

.select:focus {
    border: 0.1rem solid var(--primary-color-dark);
    outline: 0.1rem solid var(--primary-color-dark);
}

.textInput {
    position: absolute;
    box-sizing: border-box;
    width: calc(100% - 2.8rem);
    height: 100%;
    padding: .8rem 2.8rem .8rem 1rem;
    top: 0;
    left: 0;
    border: none;
    background: none;
    font-family: "Quicksand";
    font-size: 1.6rem;
    font-weight: 400;
    outline: none;
}

.select-arrow {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 2.8rem;
    height: fit-content;
    cursor: pointer;
    position: absolute;
    transform: translateY(-2rem);
    right: 0;
    top: 3.5rem;
}

.select-arrow svg {
    width: 1.7rem;
    height: 1.7rem;
  color: var(--primary-color-dark);
  transition: all ease-in-out .5s;
}

.modal {
    background-color: var(--whiteful);
}