.cardContainer {
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: "Dosis";
    font-weight: 500;
    box-sizing: border-box;
}

.header {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.title-container {
    align-self: flex-start;
    margin: 0.8rem 2.8rem 2rem;
    width: fit-content;
    height: fit-content;
}

.title {
    font-family: "Quicksand";
    letter-spacing: 0.1rem;
    font-size: 2.2rem;
    font-weight: 500;
    margin: 0;
}

.userTotalPatients,
.userTotalEvolutions,
.highestPatientEvolutions,
.lastEvolutionDoneDate,
.amountOfInactivePatients {
    width: 82%;
    margin: 0.5rem;
    display: flex;
    justify-content: space-between;
}

.totalPacientsTitle,
.totalEvolutionsTitle,
.highestPatientEvolutionsTitle,
.lastEvolutionDoneDateTitle,
.amountOfInactivePatientsTitle {
    font-size: 1.4rem;
    max-width: 35%;
}

.totalPacientsContent,
.totalEvolutionsContent,
.highestPatientEvolutionsContent,
.lastEvolutionDoneDateContent,
.amountOfInactivePatientsContent {
    text-align: end;
    font-size: 2rem;
    max-width: 55%;
}

.highestPatientEvolutionsFlex,
.lastEvolutionDoneDateContent {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.lastEvolutionDoneDateContent P {
    margin: 0;
}

.lastEvolutionDoneDateContent p,
.highestPatientEvolutionsFlex P {
    margin: 0;
}

.totalPacientsContent span,
.totalEvolutionsContent span,
.amountOfInactivePatientsContent span {
    font-weight: bold;
}

.link-separator {
    display: flex;
    width: fit-content;
    height: fit-content;
    font-size: 1.4rem;
    font-weight: 500;
    margin-top: .6rem;
}

.footer {
    display: flex;
    justify-content: space-evenly;
    width: 100%;
    margin-top: 4rem;
}

.button-separator {
    width: fit-content;
    margin: auto 1rem;
}

@media(max-width: 34rem) {
    .title {
        text-align: center;
    }

    .userTotalPatients,
    .userTotalEvolutions,
    .highestPatientEvolutions,
    .lastEvolutionDoneDate,
    .amountOfInactivePatients {
        align-items: center;
        flex-direction: column;
        width: 100%;
    }

    .totalPacientsTitle,
    .totalEvolutionsTitle,
    .highestPatientEvolutionsTitle,
    .lastEvolutionDoneDateTitle,
    .amountOfInactivePatientsTitle {
        margin-bottom: .5rem;
        text-align: center;
        max-width: 85%;
    }
    
    .totalPacientsContent,
    .totalEvolutionsContent,
    .highestPatientEvolutionsContent,
    .lastEvolutionDoneDateContent,
    .amountOfInactivePatientsContent {
        text-align: center;
        max-width: 85%;
    }
}