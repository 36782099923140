.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 65vw;
    height: 75vh;
}

.title {
    font-size: 2.5rem;
    font-weight: 500;
    letter-spacing: .5rem;
    margin: 1rem 0;
    border-bottom: 0.1rem solid grey;
    width: fit-content;
}

.body {
    text-align: justify;
    font-size: 1.5rem;
    overflow: auto;
    padding: .8rem 2rem;
    width: 100%;
    overflow-x: hidden;
    box-sizing: border-box;
}

.content {
    width: calc(100% - 1.3rem);
}

.footer {
    margin-top: 4rem;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
}

.button {
    width: 15rem;
}

.body::-webkit-scrollbar {
    width: .3rem;
    background: rgb(235, 235, 235);
}

.body::-webkit-scrollbar-thumb {
    background-color: rgb(219, 219, 219);
    border-radius: 10rem;
}