.filterMain {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 75vw;
    max-width: 45rem;
  }

  .filterMain h4 {
    margin: 0;
    font-size: 1.6rem;
  }

  .row1 {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    width: 90%;
    height: fit-content;
  }
  
  .row1-content {
    width: 45%;
    min-width: 13rem;
    margin: 3rem 1rem 0;
  }
  
  .row2 {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 90%;
    height: fit-content;
    margin-top: 4rem;
  }