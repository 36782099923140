.button-container {
    background-color: var(--primary-color-dark);
    border: .1rem solid var(--primary-color-dark);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: .6rem;
    width: 100%;
    font-weight: 400;
    font-family: "Dosis";
  }
  
.button {
    line-height: 2rem;
    height: fit-content;
    text-align: center;
    width: 100%;
    cursor: pointer;
    transition: all 0.5s;
    position: relative;
    padding: .8rem 2rem;
    color: var(--whiteful);
    font-size: 1.4rem;

    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
        background-color: rgba(255,255,255,0.1);
        transition: all 0.3s;
        border-radius: .6rem;
    }

    &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
        opacity: 0;
        transition: all 0.3s;
        transform: scale(1.2,1.2);
    }

    &:hover {
        &::before {
            opacity: 0 ;
            transform: scale(0.5,0.5);
        }
        
        &::after {
            opacity: 1;
            transform: scale(1,1);
        }
    }
}

.button-container-secondary {
    background-color: var(--background-white);
}

.button-secondary {
    color: var(--link-color);
    
    &::before {
        background-color: #d6d6d61a;
        opacity: 0;
    }

    &:hover {
        &::before {
            opacity: 1;
            transform: scale(1,1);
        }
    }
}

.disabled {
    pointer-events: none;
    filter: grayscale(1)
}

.loading-icon {
    display: inline-block;
    animation: 1.4s linear 0s infinite normal none running rotate;
    width: 15px;
    height: 15px;
}

.loading-icon circle {
    stroke: currentColor;
    stroke-dasharray: 80px, 200px;
    stroke-dashoffset: 0;
}

@keyframes rotate {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}
