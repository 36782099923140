.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    grid-template-columns: repeat(3, 1fr);
    padding: 1rem;
    font-family: "Zen Loop", cursive;
    font-weight: bold;
}

.subtitle {
    text-align: center;
    letter-spacing: 1rem;
    font-size: 2rem;
    max-width: 60%;
}