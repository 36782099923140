.evolution-card-container {
    min-width: 20rem;
    max-width: 85%;
    width: calc(100% - 5.5rem);
    height: fit-content;
    background-color: var(--whiteful);
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.2);
    padding: 1.8rem 2.5rem;
    margin: 1rem 1rem;
    border-radius: 1rem;
    border: .1rem solid var(--primary-color-dark);
    border-left: none;

    &::before {
        content: "";
        position: absolute;
        left: 0;
        bottom: 0;
        width: .9rem;
        height: 100.3%;
        background: linear-gradient(45deg, var(--primary-color-dark), var(--primary-color) 75%);
        border-top-left-radius: 1rem;
        border-bottom-left-radius: 1rem;
      }
}

.evolution-card-description {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: calc(100% - 2rem);
    padding: 1rem;
    border-bottom: .1rem solid var(--grey-line);
}

.evolution-card-description-container {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;
}

.evolution-card-description-container h4 {
    margin: 0 0 .5rem;
    font-size: 1.8rem;
}

.status {
    width: 2rem;
    height: 2rem;
    border-radius: 50%;
    box-shadow: 0 .5rem 1rem rgba(var(--blackful), .8);
    transition: all ease-in-out .3s;
    transition: ease-in-out .8s;
    box-sizing: border-box;
    transform: translate(20px, -15px);
}

.evolution-card-description span {
    font-size: 1.6rem;
    text-align: justify;
    margin-left: .5rem;
}

.evolution-card-informations {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;
}

.gdh-status {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-wrap: wrap;
    margin-top: 1rem;
}

.information-status {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-wrap: wrap;
    margin-top: 1rem;
}

.gdh-status div {
    width: max-content;
    padding-left: .5rem;
    border-left: .1rem solid var(--grey-line);
}

.information-status-subcontainer {
    width: max-content;
    padding-left: .5rem;
    border-right: .1rem solid var(--grey-line);
}

.gdh-status span, .information-status span {
    margin: 0 .5rem 0 0;
    font-size: 1.4rem;
}

.remove-button {
    display: flex;
    align-items: center;
    padding: 0;
    justify-content: center;
    text-decoration: none;
    border: none;
    background-color: var(--whiteful);
    color: var(--red);
    margin: .6rem 1rem;
    cursor: pointer;
    transition: ease-in-out .8s;
    margin: .4rem .6rem auto auto;
}

.remove-button svg {
    width: 2.2rem;
    height: 2.2rem;
}
  
.patient-status-container p {
  margin: .8rem 0;
  font-size: 1.6rem;
}

@media(max-width: 45rem) {

    .gdh-status,
    .information-status {
        flex-direction: column;
    }

    .information-status {
        text-align: end;
    }

    .information-status-subcontainer {
        min-width: 11rem;
    }
}

@media(max-width: 35rem) {
  
    .evolution-card-informations {
      flex-direction: column;
    }
    
    .information-status {
        text-align: start;
    }

    .information-status-subcontainer {
        min-width: none;
        border-left: .1rem solid var(--grey-line);
        border-right: none;
    }

    .remove-button {
        margin: .4rem auto auto .2rem;
    }
}