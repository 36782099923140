.evolutions-container {
  width: 100%;
  height: 100%;
  max-width: 120rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

/* CSS for the navbar buttons */
.evolutions-navbar {
  min-width: 20rem;
  width: fit-content;
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  margin-top: 1.8rem;
}

.navbar-buttons {
  width: 17rem;
  margin: .5rem 1.5rem;
}

.totalValueFilter {
  margin: auto auto 0 8rem;
  align-self: flex-end;
}

.evolutions-card-container {
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}