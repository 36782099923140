.loginContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 3rem;
}

.loginForm {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 30rem;
    padding-bottom: 1.5rem;
    background-color: white;
    border-radius: 2.5rem;
    z-index: 1;
    background-color: var(--background-white);
}

.login-button {
    margin-top: 1.5rem;
}

.login-button button {
    border: none;
    background: none;
    width: fit-content;
    height: fit-content;
    font-size: 1.6rem;
    letter-spacing: .2rem;
    padding: 0;
}

@media(max-width: 55rem) {
    .loginContainer {
        padding-left: 0;
    }
}