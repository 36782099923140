.homeContainer {
    display: flex;
    width: 100vw;
    align-items: center;
    justify-content: center;
}

.logoContainer {
    display: flex;
    align-items: center;
    padding-right: 3rem;
}

.logo {
    max-width: 10rem;
    padding-right: 2rem;
}

.brandContainer {
    display: flex;
    align-items: flex-start;
    height: fit-content;
}

.sloganContainer {
    max-width: 33rem;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-end;
}

.evolutions {
    display: flex;
    align-items: flex-start;
    flex-wrap: nowrap;
    font-size: 5rem;
    line-height: 5rem;
    width: fit-content;
    height: fit-content;
    font-family: "Zen Loop";
    letter-spacing: 1.8rem;
    font-weight: lighter;
    margin: 0;
    color: var(--link-color);
}

.register {
    font-size: 1.4rem;
    line-height: 1.4rem;
    width: fit-content;
    height: fit-content;
    font-family: "Dosis";
    letter-spacing: 0.2rem;
    font-weight: 500;
    margin: 0;
    color: var(--link-color);
}

.slogan {
    width: fit-content;
    height: fit-content;
    font-family: "Quicksand";
    font-size: 1.8rem;
    line-height: 3rem;
    font-weight: 400;
    margin: 0;

    & > span {
        font-family: "Zen Loop";
        letter-spacing: 0.4rem;
        font-size: 3.9rem;
        line-height: 3.9rem;
        font-style: italic;
        color: var(--link-color);
        background-color: var(--secondary-ligth);
        padding: .2rem .5rem;
        border-radius: 1rem;
    }
}

@media(max-width: 55rem) {
    .homeContainer {
        flex-direction: column;
    }

    .logoContainer {
        flex-direction: column;
        padding: 0;
        max-width: 20rem;
    }

    .logo {
        align-self: flex-end;
        transform: translateY(-1rem);
        padding-right: 1rem;
    }

    .sloganContainer {
        justify-content: center;
        align-items: flex-start;
        height: 13rem;
    }
}