.patients-container {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.search-container {
  width: 35%;
  margin-bottom: 2rem;
  margin-left: 2rem;
  /*remove the following properties after adjusting height of the input*/
  height: 5rem;
  display: flex;
  flex-direction: column-reverse;
}

.search-icon{
  width: 2rem;
  height: 2rem;
  margin: 0 .3rem;
  z-index: 2;
  opacity: 0;
  color: var(--link-color);
}

.patients-card-container {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  align-items: flex-start;
  justify-content: flex-start;
}

.no-pacients {
  font-family: "Zen Loop", cursive;
  font-size: 2.6rem;
  letter-spacing: 0.7rem;
  font-weight: 600;
}

@media(max-width: 60.8rem) {
  .search-container {
    align-self: center;
    margin-left: 0;
  }

  .patients-card-container {
    justify-content: center;
  }
}