body {
    margin: 0;
    padding: 0;
    background-color: var(--background-white);

    /* Scroll */
    ::-webkit-scrollbar {
      width: 5px;
      background: rgb(235, 235, 235);

    }
    
    ::-webkit-scrollbar-thumb {
        background-color: var(--background-cream);
        border-radius: 10px;
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
    }
    
    ::-webkit-scrollbar-thumb:hover {
      background: var(--link-hover-color); 
    }
}

.app {
    --primary-color: #E5D5B1;
    --primary-color-dark: #D6B083;
    --secondary-color: #F7F3E6;
    --secondary-ligth: #F5EFE0;

    --red: #F37471;
    --orange: #eebc7e;
    --green: #78be99;
    --grey-line: #ebebeb;
    --whiteful: #ffffff;
    --blackful: #000000;
    --dark-grey: #404040;
    --placeholder-grey: #a5a5a56c;
    --background-white: #FEFEFE;
    --backgound-slide: #FFA957;
    --background-cream: #eedebb;
    
    --red-neon: #f50057;
    --yeallow-neon: #FFD400;
    
    --green-message: #40aa06;
    --green-message-label: #03450b;
    --danger-message: #d6044d;
    --danger-message-label: #57020c;
    --warning-message: #d9b501;
    --warning-message-label: #7b4e00;

    --link-color: #BC873F;
    --link-hover-color: #957332;

    font-family: "Dosis" !important;
    background-color: var(--background-white);

    display: flex;
    position: relative;
    align-items: flex-start;
    justify-content: flex-start;
    width: -webkit-fill-available;
    height: -webkit-fill-available;
    overflow: hidden auto;
}

.navbar-container {
    z-index: 10;
}

.portal-container {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    width: calc(100vw - 20rem);
    height: 100vh;
    margin-left: 20rem;
    transition: .3s all ease-in-out .3s;
}

.router-content {
    display: flex;
    flex-direction: column;
    width: calc(100% - 5rem);
    margin: 0 1.5rem;
    padding: 1rem;
    max-width: 165rem;
}

/* when is the login page */

.navbar-container-login-page {
    display: none;
}

.portal-container-login-page {
    margin: 0;
    width: 100%;
}

.router-content-login-page {
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    max-width: calc(100vw - 5rem);
}

@media(max-width: 106rem) {
    .navbar-container-login-page {
        display: none;
    }
}

@media(max-width: 66.2rem) {
    .portal-container {
        margin-left: 0rem;
        width: 100%;
    }

    .router-content {
        width: calc(100% - 5rem);
        margin: 0 1.5rem 0 1.5rem;
    }
}