.main {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 75vw;
  max-width: 55rem;
  overflow-y: auto;
}

.cardful {
  border-radius: 3.5rem;
  box-shadow: 0 1rem 2rem rgb(218 218 218 / 25%);
}

.main h4 {
  margin: 0;
  font-size: 1.8rem;
  margin-bottom: 1.5rem;
}

.form {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  overflow-y: auto; 
}

.form::-webkit-scrollbar {
  border-radius: 0.25rem;
  width: 0.2rem;
}

.form::-webkit-scrollbar-thumb {
  border-radius: 0.25rem;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background-color: var(--primary-color-dark);
  height: 10px;
}

.row1 {
  width: 90%;
  min-width: 13rem;
}

.row1-content {
  margin: 0 3.5rem;
  min-width: 13rem;
}

.row2 {
  display: flex;
  justify-content: center;
  width: 90%;
  flex-wrap: wrap;
}

.row2-content {
  max-width: 13rem;
  box-sizing: border-box;
  width: fit-content;
  margin: 0 1rem;
}
  
.selectors {
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
  height: fit-content;
}

.row3 {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  height: fit-content;
}

.row3-content {
  min-width: 20rem;
  margin: 0 1rem;
}

.row4 {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 35%;
  height: fit-content;
  margin: 3rem 0 2rem;
}