.tooltip-container {
    position: relative;
    display: inline-block;
  }
  
  .tooltip {
    position: absolute;
    background-color: #333;
    color: #fff;
    padding: 5px;
    border-radius: 4px;
    z-index: 1000;
    transform: translateX(-50%);
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
    height: fit-content;
    width: max-content;
    box-sizing: border-box;
    cursor: default;
  }

  .children-container {
    width: fit-content;
    height: fit-content;
  }
  
  .children-container:hover .tooltip {
    opacity: 1;
  }
  