.main {
    display: flex;
    background: white;
    font-family: 'Quicksand';
    flex-direction: column;
    align-items: center;
    border-radius: 1.5rem;
    margin: 2rem;
    padding: 2rem;
    z-index: 1;
    max-width: 75vw;
}

.btnClose {
    display: flex;
    position: relative;
    background-size: cover;
    width: 100%;
    height: 2rem;
    justify-content: flex-end;
}

.btnClose img {
    cursor: pointer;
    transition: ease-in-out 0.6s;
}

.shadow {
    position: relative;
}

.shadow:before {
    content: "";
    z-index: -1;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: linear-gradient(-45deg, #eee23c 0%, #ff930f 100%);
    transform: scale(0.96);
    filter: blur(1.5rem);
    opacity: var(0.7);
    transition: opacity 0.3s;
    border-radius: inherit;
}

.shadow::after {
    content: "";
    z-index: -1;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: inherit;
    border-radius: inherit;
}

.info-card {
    display: flex;
    align-items: center;
    width: calc(100% - 8rem);
    font-size: 1.5rem;
    font-weight: bold;
    grid-column: 1/3;
    grid-row-start: 2;
    margin-bottom: auto;
}

.user-card {
    width: calc(100% - 8rem);
    grid-column: 3/4;
    grid-row-start: 2;
    font-weight: bold;
    margin-bottom: auto;
}

.patientsList {
    max-width: 34rem;
    padding: 0;
    margin: 1rem;
    border-radius: 1rem;
}

.patientsList:before {
    transform: scale(0.93, 0.91);
    filter: blur(1.2rem);
}

.evolutionsList {
    padding: 1rem 1.7rem;
    margin: 1rem;
    border-radius: 1rem;
    width: 40%;
}

.evolutionsList:before {
    transform: scale(0.96, 0.9);
    filter: blur(1.2rem);
}

.modal {
    max-height: 80%;
    height: fit-content;
    overflow: hidden;
}