.overlay {
  position: absolute;
  width: 100vw;
  height: 100vh;
  z-index: 4;
}

.menu-icons {
  position: absolute;
  left: 0;
  top: 0;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: fit-content;
  width: 3rem;
  overflow: hidden;
  margin: 2rem 0 0 2rem;
  z-index: 6;
  transition: all ease-in-out .3s;
}

.menu-icon {
  min-width: 3rem;
  width: 3rem;
  height: 3rem;
  color: var(--dark-grey);
  transition: all ease-in-out .2s;
  cursor: pointer;
}

.container {
  position: fixed;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 20rem;
  height: 100vh;
  background-image: linear-gradient(209.22deg, #ffffff 4.25%, #f1f1f1 95.6%);
  transition: all ease-in-out .3s;
  box-shadow: 0 .5rem 2rem rgba(0, 0, 0, 0.2);
  z-index: 5;
  overflow-y: auto;
  overflow-x: hidden;
}

.container::-webkit-scrollbar {
  border-radius: 0.25rem;
  width: 0.2rem;
}

.container::-webkit-scrollbar-thumb {
  border-radius: 0.25rem;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background-color: var(--primary-color-dark);
  height: 10px;
}

.content {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: fit-content;
  padding-top: 5rem;
}

.logout-container {
  position: absolute;
  top: 0;
  right: 0;
  margin: 2rem 2rem 0 0;
  background: none;
  font-size: 2em;
}

.navbar-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-bottom: 4rem;
}

.image-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 85%;
  height: fit-content;
  border-top: .1rem solid var(--primary-color-dark);
  border-bottom: .1rem solid var(--primary-color-dark);
  margin: 1rem .5rem;
  box-sizing: border-box;
  background: #f5efe0;
  background: linear-gradient(209.22deg, #ffffff 4.25%, #f1f1f1 95.6%);
}

.logo {
  padding: 1.2rem 0 3rem;
  width: 14rem;
}

.avatar-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 10rem;
  margin-top: -30px;
}

.avatar-image {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 10rem;
  height: 10rem;
  border-radius: 50%;
  background-color: var(--background-white);
  border: .1rem solid var(--primary-color-dark);
  overflow: hidden;
  box-sizing: border-box;
  overflow: hidden;

  & > img {
    width: 100%;
  }
}

.avatar-default {
  width: 8rem;
  height: 8rem;
  color: var(--placeholder-grey);
}

.navbar-items {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  height: fit-content;
  padding-left: 4rem;
}

.navbar-list {
  margin: 0px;
  padding: 0px;
  list-style: none;
}

.navbar-item {
  font-size: 1.4rem;
  margin-bottom: 2rem;
  padding-left: 1rem;
  border-left: .1rem solid var(--link-color);
}

.navbar-item:hover {
  border-left: .1rem solid var(--link-hover-color);
}

.link-active {
  border-left: .1rem solid var(--link-hover-color);
}

.link-active a {
  color: var(--link-hover-color);
}

.lovely-disclaimer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 75%;
  height: fit-content;
  font-size: 1.5rem;
  padding-top: .5rem;
  margin-bottom: .5rem;
  border-top: .1rem solid #ebebeb;
  opacity: .9;
}

.heart-icon {
  width: 1.5rem;
  margin-left: .3rem;
  color: var(--red);
}

@media(min-width: 66.2rem) {
  .menu-icons {
      display: none;
  }
}

@media(max-width: 66.2rem) {
  .container {
    background-color: var(--secondary-ligth);
  }
}