.main {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.content-container {
    display: flex;
    width: 100%;
    align-items: flex-start;
    justify-content: center;
}

.left-column {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    flex: .67;
    margin: 1.5rem;
    box-shadow: 0 .5rem 2rem rgba(0, 0, 0, 0.2);
    padding: 1.8rem 2.5rem;
    border-radius: 2rem;
}

.rigth-column {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    flex: .33;
    margin: 1.5rem;
    box-shadow: 0 .5rem 2rem rgba(0, 0, 0, 0.2);
    padding: 1.8rem 2.5rem;
    border-radius: 2rem;
}

@media(max-width: 61rem) {
    .content-container {
        flex-direction: column;
        align-items: center;
    }

    .left-column,
    .rigth-column {
        width: 75%;
    }
}