.footer-container {
    height: fit-content;
    width: 75%;
    margin: 4rem auto 2rem auto;
    padding-top: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-top: .1rem solid #ebebeb;
}

.social-list {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1rem 0 0 0;
    margin: 0;
}

.social-list li {
    list-style-type: none;
}

.social-list a {
    width: fit-content;
    height: fit-content;
    margin: 0 2rem;
    color: var(--primary-color-dark);
}

.social-list svg {
    font-size: 2.1rem;
}

.developer-container {
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--primary-color-dark);
    margin-top: .5rem;
    font-size: 1.6rem;
}

.developer-container p {
    margin: .5rem;
}

@media (max-width: 35rem) {
    .developer-container {
        flex-direction: column;
    }

    .developer-container span {
        display: none;
    }
}