.main {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 75vw;
    max-width: 35rem;
}

.title {
    display: flex;
    justify-content: center;
    align-items: center;
}

.title svg {
    margin-right: 1rem;
    width: 2.8rem;
    height: 2.8rem;
}

.warn svg {
    color: var(--yeallow-neon)
}

.danger svg {
    color: var(--red-neon)
}

.title h4 {
  margin: 0;
  font-size: 1.8rem;
}

.main p {
    margin: 1.4rem 0 0;
    font-size: 1.4rem;
    width: 75%;
    text-align: justify;
}

.main p span {
    font-weight: bold;
}

.input-container {
    width: 75%;
    margin: 2rem 0;
}

.buttons-container {
    height: fit-content;
    width: 75%;
}

.double-buttons {
    width: 100%;
    height: fit-content;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.button-container {
    height: fit-content;
    width: 100%;
}

.double-button {
    width: fit-content;
    height: fit-content;
    margin-top: 2rem;

    &:last-child { 
        margin-left: 1.5rem;
    }
}