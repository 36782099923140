.main {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 75vw;
  max-width: 45.5rem;
  overflow-y: auto;
}

.main h4 {
  margin: 0;
  font-size: 1.8rem;
  margin-bottom: 1.5rem;
}

.form-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  overflow-y: auto;
}

.form-container::-webkit-scrollbar {
  border-radius: 0.25rem;
  width: 0.2rem;
}

.form-container::-webkit-scrollbar-thumb {
  border-radius: 0.25rem;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background-color: var(--primary-color-dark);
  height: 10px;
}

.row1, .row2 {
  display: flex;
  justify-content: center;
  width: 90%;
  flex-wrap: wrap;
}

.row1-content,
.row2-content {
  min-width: 13rem;
  box-sizing: border-box;
  width: fit-content;
  margin: 0 1rem;
}

.row3 {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    width: 100%;
    height: fit-content;
    margin-top: 2rem;
  }
  
  .selectors {
    display: flex;
    align-items: center;
    justify-content: center;
    width: fit-content;
    height: fit-content;
    margin: 0 1rem;
  }

  .row4 {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 35%;
    height: fit-content;
    margin-top: 3rem;
  }