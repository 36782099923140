.patient-card-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32rem;
  height: fit-content;
  margin: 1.5rem;
  padding: 1.8rem;
  box-shadow: 0 1rem 2rem rgb(218 218 218 / 20%);
  border-radius: .8rem;
  border: none;
  background: var(--whiteful);
  border: 1px solid var(--primary-color);
}

.patient-card-content {
  display: flex;
  flex-direction: column;
  background-color: var(--whiteful);
  border-radius: .8rem;
}

.patient-status-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 20%;
  background-color: var(--whiteful);
}

.patient-status, .patient-status-buttons {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.remove-button,
.edit-button {
  display: flex;
  align-items: center;
  padding: 0;
  justify-content: center;
  text-decoration: none;
  border: none;
  background-color: var(--whiteful);
  width: 2.3rem;
  height: 2.3rem;
  margin: .6rem 0 .6rem 1rem;
}

.remove-button {
  color: var(--red);
}

.edit-button {
  color: var(--link-color);
}
  
.patient-status-container p {
  margin: .8rem 0;
  font-size: 1.6rem;
}
  
.status {
  position: relative;;
  margin: 1rem;
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  overflow: hidden;
  box-shadow: 0 5px 10px rgba(var(--blackful), 0.8);
  transform-origin: center top;
  transform-style: preserve-3d;
  transform: translateZ(0);
}

.remove-button,
.edit-button,
.status {
  cursor: pointer;
}

.patient-name-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: fit-content;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  background: linear-gradient(90deg, #fff 0%, #F7F3E6 45%, #F7F3E6 55%, #fff 100%);
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 31.25rem;
}
  
.patient-name-container p {
  margin: 1rem auto;
  font-size: 2.2rem;
}

.patient-information-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  height: fit-content;
  margin: .5rem;
}
  
.patient-information-container div {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin: .5rem .4rem;
  font-size: 1.2rem;
  min-width: 8rem;
  background-color: var(--whiteful);
}
  
.patient-information-container p {
  font-weight: bold;
  margin: 0 0 .3rem;
}

@media(max-width: 28rem) {
  
  .patient-name-container p {
    font-size: 1.8rem;
  }
}