.list {
    position: relative;
    margin-right: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: 75vw;
    max-width: 35rem;
  }
  .list h2 {
    font-size: 1.8rem;
    margin-bottom: 10px;
  }
  .list ul {
    position: relative;
    padding: 0;
    width: 100%;
  }
  .list ul li {
    position: relative;
    left: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: var(--link-color);
    list-style: none;
    margin: 4px 0;
    border-left: 2px solid #FFA957;
    transition: 0.5s;
    cursor: pointer;
    width: 100%;
    font-size: 1.4rem;
  }
  .list ul li:hover {
    left: 10px;
  }
  .list ul li span {
    position: relative;
    padding: 8px;
    padding-left: 12px;
    display: inline-block;
    z-index: 1;
    transition: 0.5s;
  }
  .list ul li:hover span {
    color: var(--dark-grey);
  }
  
  .list ul li:before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background: #FFA957;
    transform: scaleX(0);
    transform-origin: left;
    transition: 0.5s;
  }
  .list ul li:hover:before {
    transform: scaleX(1);
  }

  .icons-container {
    margin: 0 1rem;
    min-width: 7.8rem;
  }

  .readIcon,
  .undoIcon,
  .trashIcon {
    width: 2rem;
    height: 2rem;
    margin: 0 .3rem;
    z-index: 2;
    opacity: 0;
    color: var(--link-color);
  }

  .clickable  {
    width: 100%;
    height: 2.2rem;
    position: absolute;
    z-index: 3;
  }
  
  .readIcon {
    transition: .3s opacity ease-in-out .3s;
  }
  
  .undoIcon {
    transition: .5s opacity ease-in-out .3s;
  }
  
  .trashIcon {
    transition: .7s opacity ease-in-out .3s;
  }

  .list ul li:hover .readIcon,
  .list ul li:hover .undoIcon,
  .list ul li:hover .trashIcon {
    opacity: 1;
    color: var(--dark-grey);
  }
  
  .list ul li:hover .readIcon {
    transition: .7s opacity ease-in-out .3s;
  }
  
  .list ul li:hover .undoIcon {
    transition: .5s opacity ease-in-out .3s;
  }
  
  .list ul li:hover .trashIcon {
    transition: .3s opacity ease-in-out .3s;
  }