.title-container {
    display: flex;
    width: 30rem;
    justify-content: flex-start;
    align-items: center;
}

.title {
    box-sizing: border-box;
    font-weight: 600;
    margin: 1.5rem 2rem .5rem 1rem;
}

.checkbox {
    width: fit-content;
}

.text {
    margin: 0;
}