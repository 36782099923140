.main {
    align-items: center;
    background-color: hsla(320, 2%, 30%, 0.096);
    backdrop-filter: blur(10px);
    display: flex;
    height: 100vh;
    justify-content: center;
    position: fixed;
    width: 100vw;
    flex-wrap: wrap;
    z-index: 999;
    left: 0;
    top: 0;
    max-width: 100vw;
}

.blur {
    height: 100vh;
    width: 100vw;
    cursor: pointer;
    position: absolute;
    overflow: hidden;
}