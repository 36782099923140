.content {
    width: 100%;
    height: 100%;
    position: relative;
}

.container {
  height: 100%;
  width: 100%;
  background: #ffffff;
}

.card {
  position: relative;
  margin: auto;
  height: 65vh;
  min-height: 46rem;
  width: 40vw;
  /* border: 1px solid #292929; */
  border-radius: 22px;
  top: 10%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  text-align: center;
}

.hot-air {
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  top: 30%;
  animation: hot-air 5s ease-in-out 1s infinite;
  z-index: 5;
  /* visibility: hidden; */
}

.message {
    margin: 0 5rem 15rem;
    font-size: 2.2rem;
    font-weight: 700;
}

.cloud {
    animation-timing-function: linear;
    animation-delay: 0s;
    position: absolute;
    padding: 0;
    margin: 0;
    left: -350px;
    animation-name: cloud;
}

.shadow {
  box-shadow: 0px 50px 50px rgba(0, 0, 0, 0.15);
}

.gradient {
  background: linear-gradient(209.22deg, #ffffff 4.25%, #f1f1f1 95.6%);
}

@keyframes hot-air {
  0% {
  }
  50% {
    top: 20%;
  }
  100% {
  }
}

@keyframes cloud {
  from {
    left: -350px;
    visibility: visible;
  }
  to {
    left: 100vw;
    visibility: hidden;
  }
}

@media (max-width: 48rem) {
  .card {
    width: 70vw;
  }
}
