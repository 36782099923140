.link-container {
    display: flex;
    width: 100%;
    height: fit-content;
    cursor: pointer;
    background-color: var(--background-white);
}

.link {
    display: flex;
    width: 100%;
    height: fit-content;
    text-decoration: none;
    color: var(--link-color);
    font-weight: 500;
}

.link:hover {
    color: var(--link-hover-color);
}

.modal-link {
    background-color: var(--whiteful);
}

.menu-link {
    background-color: transparent;
}