.patient-section-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: -webkit-fill-available;
  height: fit-content;
  border-bottom: .1rem solid var(--grey-line);
  padding: 1.8rem 2rem;
  margin: 4rem 0 2rem;
}

.patient-section-content {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  height: fit-content;
}

.patient-section {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  flex: .7;
}

.patient-section-name {
  font-size: 3rem;
  margin-bottom: 2rem;
  letter-spacing: .4rem;
}

.patient-section-informations {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: flex-start;
  width: fit-content;
}

.patient-section-informations div {
  margin-right: 3rem;
  margin-bottom: 1rem;
  width: 16rem;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.information-title {
  font-size: 1.2rem;
  margin-right: .5rem;
  line-height: 1.8rem;
}

.information-content {
  font-size: 1.6rem;
  line-height: 1.6rem;
  white-space: wrap;
  width: fit-content;
  margin: 0;
}

.patient-section-values {
  display: flex;
  flex-direction: column;
  flex: .3;
  align-items: flex-end;
  margin-top: .6rem;
  padding: 1rem;
  border: .1rem solid var(--secondary-color);
  box-shadow: 0 .5rem 1rem rgba(185, 185, 185, 0.8);
  border-radius: .8rem;
  min-width: 18rem;
  box-sizing: border-box;
}

.patient-section-values div {
  transition: ease-in-out 0.5s;
}

.value-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-width: 100%;
  box-sizing: border-box;
  cursor: pointer;
}

.value-header-expanded {
  margin-bottom: 1rem;
}

.value-header-chevron {
  display: flex;
  align-items: center;
  justify-content: center;
  height: fit-content;
  width: fit-content;
}

.value-header-chevron svg {
  width: 1.5rem;
  height: 1.5rem;
  color: var(--dark-grey);
}

.value-itens {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  height: 0px;
  overflow: hidden;
}

.value-itens-show {
    height: 3.5rem;
}

.patient-section-values span {
  min-width: 5.5rem;
  white-space: nowrap;
}

@media(max-width: 34rem) {
  .patient-section-content {
    flex-direction: column;
  }

  .patient-section-informations {
    flex-direction: row;
  }

  .patient-section-values,
  .value-itens {
    align-items: flex-start;
  }

  .value-header {
    flex-direction: row-reverse;
  }
}