.main {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 75vw;
    max-width: 40rem;
}

.title {
    display: flex;
    justify-content: center;
    align-items: center;
}

.title svg {
    margin-right: 1rem;
    width: 2.8rem;
    height: 2.8rem;
    color: var(--link-color)
}

.title h4 {
  margin: 0;
  font-size: 1.8rem;
}

.main p {
    margin: 2.4rem 0 2.6rem;
    font-size: 1.4rem;
    width: 75%;
    text-align: center;
}

.button-container {
    height: fit-content;
    width: fit-content;
}