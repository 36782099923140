.page-container {
    display: flex;
    justify-content: flex-start;
    width: calc(100% - 0rem);
    height: fit-content;
    margin: 2.5rem 0 2.5rem 5rem;
    z-index: 4;
}

.page-name {
    font-size: 5rem;
    border-bottom: solid 0.1rem var(--primary-color-dark);
    letter-spacing: 1.8rem;
    font-weight: lighter;
    margin: 0;
    text-align: center;
}

.new-patient-page {
    justify-content: center;
}

@media(max-width: 61rem) {
    .page-container {
        justify-content: center;
        margin: 3.5rem 0;
    }
}

@media(max-width: 33.24rem) {
    .page-container {
        margin-bottom: 0;
    }

    .page-name {
        font-size: 3rem;
        letter-spacing: 1rem;
    }
}