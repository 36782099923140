.main {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    width: 100%;
    height: 7.5rem;
    background-color: var(--background-white);
}

.main-aux {
    height: fit-content;
    width: fit-content;
}

.main-only-input {
    height: 3rem;
}

.placeHolderAsTitle {
    font-family: "Quicksand";
    font-size: 1.4rem;
    line-height: 1.6rem;
    font-weight: 400;
    margin: 2.5rem auto 0 0;
    padding: 0.2rem 0.2rem 0;
    color: #3d3d3d;
    transition: all ease-in-out 0.8s;
    cursor: pointer;
    border-bottom: solid 0.1rem var(--primary-color-dark);
    z-index: 1;
}

.placeHolder {
    color: #818181;
    border-bottom: 0rem solid var(--primary-color-dark);
    transform: translateY(2.7rem);
    font-size: 1.4rem;
    letter-spacing: 0.3rem;
    padding-bottom: 0.3rem;
}

.input-container {
    position: relative;
}

.search-icon {
    width: 1.7rem;
    height: 1.7rem;
    cursor: pointer;
    position: absolute;
    transform: translate(-0.3rem ,.8rem);
    right: 0;
    color: var(--link-color);
}

.search-icon svg {
    width: 100%;
    height: 100%;
}


.input {
    width: 100%;
    height: fit-content;
    font-family: "Quicksand";
    font-size: 1.6rem;
    font-weight: 400;
    margin: 0.4rem 0 0 0;
    border: none;
    border-bottom: 0.1rem solid var(--primary-color);
    transition: all ease-in-out 0.8s;
    background-color: var(--background-white);
    box-sizing: border-box;
}

.input-aux {
    width: fit-content;
}

.input::-webkit-search-cancel-button {
    appearance: none;
    background-color: transparent;
    background-image: url('../../img/erase.svg');
    background-size: contain;
    background-repeat: no-repeat;
    width: 1.7rem;
    height: 1.7rem;
    border: none;
    cursor: pointer;
}

.search-input {
    padding-right: 2.3rem;
}

.modal-input {
    background-color: var(--whiteful);
}

.underlineAsNotPlaceHolder {
    border-bottom: 0.1rem solid var(--primary-color);
}

.input:not(:placeholder-shown) {
    border-bottom: 0.1rem solid var(--primary-color-dark);
}
  
.input:placeholder-shown {
    border-bottom: 0.1rem solid var(--primary-color);
}

.input:focus {
    box-shadow: 0 0 0 0;
    outline: 0;
    border-bottom: 0.1rem solid var(--primary-color-dark);
}

.eyeIcon {
    width: 1.7rem;
    height: 1.7rem;
    cursor: pointer;
    position: absolute;
    transform: translateY(-2rem);
    right: 0;
    color: var(--link-color);
}

.eyeIcon svg {
    width: 100%;
    height: 100%;
}

.input-aux-container {
    display: flex;
    width: fit-content;
    align-items: center;
}

.aux-text-container {
    margin-left: .8rem;

    & > label {
        font-size: 1.4rem;
        text-transform: uppercase;
    }
}