.main {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 75vw;
  max-width: 60rem;
  overflow-y: auto;
}

.main h4 {
  margin-top: 0;
  font-size: 1.8rem;
  margin-bottom: 1.5rem;
}

.form {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  overflow-y: auto;
}

.form::-webkit-scrollbar {
  border-radius: 0.25rem;
  width: 0.2rem;
}

.form::-webkit-scrollbar-thumb {
  border-radius: 0.25rem;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background-color: var(--primary-color-dark);
  height: 10px;
}

.row1 {
  display: flex;
  justify-content: center;
  width: 90%;
  flex-wrap: wrap;
}

.row1-content {
  min-width: 13rem;
  box-sizing: border-box;
  width: fit-content;
  margin: 0 1rem;
}

.row2 {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
  height: fit-content;
  margin-top: 2rem;
}

.selectors {
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
  height: fit-content;
  margin: 0 1rem;
}

.row3 {
  width: 75%;
}

.row4 {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
  width: 75%;
  height: fit-content;
}

.row4 textarea {
  border-radius: 0.7rem;
  padding: 0.5rem;
  border-color: var(--primary-color-dark);
  outline-color: var(--primary-color-dark);
  width: 100%;
  height: 15rem;
  max-width: 45rem;
  box-sizing: border-box;
}

textarea::-webkit-scrollbar {
  width: 0.3rem;
}

textarea::-webkit-scrollbar-thumb {
  border-radius: 0.25rem;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background-color: var(--link-color);
  height: 10px;
}

.row5 {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 35%;
  height: fit-content;
}
