.main {
    position: fixed;
    right: 0;
    top: 0;
    z-index: 1000;
    display: flex;
    align-items: center;
    justify-content: center;
    width: fit-content;
    height: fit-content;
    margin: 4rem 2rem 0 0;
    transition: all ease-in-out .8s;
}

.message-container {
    display: flex;
    justify-items: center;
    align-items: center;
    width: fit-content;
    max-width: 80vw;
    border-radius: 0.8rem;
    transition: all ease-in-out .8s;
    font-size: 2rem;
    font-weight: 500;
    line-height: 2rem;
    padding: .5rem 2rem .8rem;
    background-image: linear-gradient(190deg, #ffffff 4.25%, #f1f1f1 95.6%);

    &::before {
        content: "";
        position: absolute;
        left: 0;
        bottom: 0;
        width: .8rem;
        height: 100%;
        border-bottom-left-radius: .8rem;
        border-top-left-radius: .8rem;
      }
}

.success {
    color: var(--green-message-label);
    border: 0.1rem solid var(--green-message);
}

.success::before {
    background: var(--green-message);
}

.warning {
    color: var(--warning-message-label);
    border: 0.1rem solid var(--warning-message);
}

.warning::before {
    background: var(--warning-message);
}

.error {
    color: var(--danger-message-label);
    border: 0.1rem solid var(--danger-message);
}

.error::before {
    background: var(--danger-message);
}

.hide-message {
    transform: translateX(calc(100% + 3rem));
}